// components/AnimatedCounter.jsx
import styled from 'styled-components';

const CounterWrapper = styled.div`
  display: flex;
  justify-content: center;
  overflow: hidden;
  height: ${props => props.size};
  font-size: ${props => props.size};
  line-height: ${props => props.size};
  // height: 0.9rem;
  // font-size: 0.9rem;
  // line-height: 0.9rem;
`;

const Digits = styled.div`
  transition: ${props => props.$duration}ms all;
  transition-timing-function: cubic-bezier(0, 0.99, 1, 1.01);
  color: ${props => props.color};
  font-family: ${props => props.fontFamily};
  margin-top: ${props => `calc(-${props.value}em)`};
  transition-delay: ${props => props.$delay}ms;
`;

const Digit = styled.div``;

export default function AnimatedCounter({
  value,
  duration = 1000,
  size = "1rem",
  color = "inherit",
  fontFamily = "inherit"
}) {
  return (
    <CounterWrapper size={size}>
      {(value + "").split("").map((val, idx) => (
        <Digits
          key={idx}
          value={val}
          color={color}
          fontFamily={fontFamily}
          $duration={duration}
          $delay={((value + "").split("").length - idx) * 20}
        >
          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((digit) => (
            <Digit key={digit} data-val={digit}>
              {digit}
            </Digit>
          ))}
        </Digits>
      ))}
    </CounterWrapper>
  );
}