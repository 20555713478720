// AppStyles.js
import styled from 'styled-components';

// Styled Loader Component
export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #000000;
  color: white;
`;

// Right-sidebar on desktop attempt
// export const AppContainer = styled.div`
//   display: flex;
//   justify-content: flex-start;
//   align-items: flex-start;
//   width: 100%;
//   height: 100vh;
// `;

// export const MainSidebar = styled.div`
//   flex-shrink: 0;
//   width: 250px;
//   height: 100vh;

//   @media (max-width: 768px) {
//     display: none;
//   }
// `;

// export const ContentWrapper = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: flex-start;
//   width: 100%;
//   max-width: 1400px; /* 800px content + 300px blank + 300px sidebar */
//   margin: 0 auto; /* Centers the whole layout */
// `;

// export const BlankSidebar = styled.div`
//   width: 300px;
//   flex-shrink: 0;
//   background-color: transparent; /* No visual display */
  
//   @media (max-width: 1390px) {
//     display: none; /* Hide on smaller screens */
//   }
// `;

// export const PageContent = styled.div`
//   max-width: 800px;
//   width: 100%;
//   padding-left: 20px;
//   padding-right: 20px;
//   z-index: 1;
//   margin-left: ${props => props.$sidebarMinimized ? '0' : '240px'};
//   transition: margin-left 0.3s ease;

//   @media (max-width: 768px) {
//     padding-left: 0px;
//     padding-right: 0px;
//     margin-left: 0;
//     width: 100%;
//   }

//   @media (min-width: 768px) and (max-width: 1200px) {
//     margin-left: ${props => props.$sidebarMinimized ? '80px' : '260px'};
//     margin-right: ${props => props.$sidebarMinimized ? '20px' : '0'};
//   }

//   @media (min-width: 1390px) {
//     margin-left: 0; /* No shifting content at large screen sizes */
//     margin-right: 0;
//   }
// `;


// export const SecondarySidebar = styled.div`
//   position: sticky;
//   top: 70px; /* Aligns the sticky sidebar to the top of the viewport */
//   margin-top: 70px;
//   // padding-left: 30px;
//   width: 300px;
//   flex-shrink: 0;
//   background-color:rgb(0, 0, 0); /* Optional: distinguish visually */
//   // padding: 16px;
//   // padding-left: 16px;

//   @media (max-width: 1390px) {
//     display: none; /* Hide on smaller screens */
//   }
// `;

export const AppContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const PageContent = styled.div`
  flex: 1;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: ${props => props.$sidebarMinimized ? '0' : '240px'};
  transition: margin-left 0.3s ease;
  max-width: ${props => props.$isMusicRoute ? '1200px' : '800px'};

  @media (max-width: 768px) {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 0;
    width: 100%;
  }

  @media (min-width: 768px) and (max-width: 1200px) {
    max-width: 800px;
    margin-left: ${props => props.$sidebarMinimized ? '80px' : '260px'};
    margin-right: ${props => props.$sidebarMinimized ? '20px' : '0'};
  }

  @media (min-width: 768px) and (max-width: 1400px) {
    max-width: 800px;
  }
`;

// Old right side bar attempt
// export const SecondarySidebar = styled.div`
//   position: absolute;
//   right: 150px;
//   top: 40px;
//   width: 300px;
//   height: 100vh; /* Full height */
//   z-index: 2;

//   @media (max-width: 1720px) {
//     display: none; /* Hide right sidebar on smaller screens */
//   }
// `;

export const NewPostButton = styled.button`
  position: fixed;
  top: 80px;            /* Adjust this value for vertical positioning */
  left: 50%;
  transform: translateX(-50%);  /* Center the button horizontally */
  z-index: 1000;        /* Ensure it appears above other elements */
  padding: 10px 20px;
  background-color: #007bff; /* Blue background */
  color: white;
  border: none;
  border-radius: 25px;
  font-size: 16px;
  cursor: pointer;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3; /* Darker blue on hover */
  }
`;

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`;