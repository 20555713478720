// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.linear-overlay {
  background-image: linear-gradient(
      -75deg,
      rgba(var(--overlay-color), 0.1) calc(var(--x) + 20%),
      rgba(var(--overlay-color), 0.5) calc(var(--x) + 25%),
      rgba(var(--overlay-color), 0.1) calc(var(--x) + 100%)
  );
  mask:
      linear-gradient(black, black) content-box,
      linear-gradient(black, black);
  -webkit-mask:
      linear-gradient(black, black) content-box,
      linear-gradient(black, black);
  mask-composite: exclude;
  -webkit-mask-composite: xor;
}

/* width */
/* Track */
/* Handle */
/* Handle on hover */


::-webkit-scrollbar {
	width: 10px;
  }
  

::-webkit-scrollbar-track {
background: #000;
}


::-webkit-scrollbar-thumb {
background: #121212;
}


::-webkit-scrollbar-thumb:hover {
background: #555;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA;EACE;;;;;GAKC;EACD;;mCAEiC;EACjC;;mCAEiC;EACjC,uBAAuB;EACvB,2BAA2B;AAC7B;;AAEA,UAAU;AACV,UAAU;AACV,WAAW;AACX,oBAAoB;;;AAGpB;CACC,WAAW;EACV;;;AAGF;AACA,gBAAgB;AAChB;;;AAGA;AACA,mBAAmB;AACnB;;;AAGA;AACA,gBAAgB;AAChB","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n.linear-overlay {\n  background-image: linear-gradient(\n      -75deg,\n      rgba(var(--overlay-color), 0.1) calc(var(--x) + 20%),\n      rgba(var(--overlay-color), 0.5) calc(var(--x) + 25%),\n      rgba(var(--overlay-color), 0.1) calc(var(--x) + 100%)\n  );\n  mask:\n      linear-gradient(black, black) content-box,\n      linear-gradient(black, black);\n  -webkit-mask:\n      linear-gradient(black, black) content-box,\n      linear-gradient(black, black);\n  mask-composite: exclude;\n  -webkit-mask-composite: xor;\n}\n\n/* width */\n/* Track */\n/* Handle */\n/* Handle on hover */\n\n\n::-webkit-scrollbar {\n\twidth: 10px;\n  }\n  \n\n::-webkit-scrollbar-track {\nbackground: #000;\n}\n\n\n::-webkit-scrollbar-thumb {\nbackground: #121212;\n}\n\n\n::-webkit-scrollbar-thumb:hover {\nbackground: #555;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
