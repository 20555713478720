import React, { useState, useEffect, useCallback, useContext } from 'react'; //, useRef
import { AxiosContext } from '../../App';
import axios from 'axios';
import styled from 'styled-components';
import InfiniteScroll from 'react-infinite-scroll-component';
import Post from './Post';
import { BarLoader } from 'react-spinners';
import { useParams } from 'react-router-dom'; //, useLocation
import FloatingActionButton from '../shared/FloatingActionButton';
import {
  SpinnerContainer, 
} from '../../styles/shared/SharedStyles';

// const ParentChainContainer = styled.div`
//   word-break: break-word;
// `;

const ChainContainer = styled.div`
  position: relative;
  margin-bottom: -20px;
  &::after {
    content: '';
    position: absolute;
    height: 20px;
    left: 24px;
    top: -20px;
    bottom: 0;
    width: 2px;
    background: #212121;

    @media (max-width: 768px) {
      left: 15px;
    }
  }
`;

const ThreadContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 25px;
`;

const ReplyContainer = styled.div`
  flex-direction: column;
  display: flex;
  gap: 20px;
  margin-left: 20px;
  border-left: 2px solid #111;
  padding-left: 24px;
  word-break: break-word;

  @media (max-width: 768px) {
    margin-left: 15px;
    padding-left: 15px;
    padding-right: 4px;
  }
`;

function ViewThread() {
  const axiosInstance = useContext(AxiosContext);
  const { postId } = useParams();
  // const location = useLocation();
  const [originalPost, setOriginalPost] = useState(null); // location.state?.post || 
  const [parentChain, setParentChain] = useState([]);
  const [replies, setReplies] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const isReplyThread = postId.startsWith('reply_');
  const actualPostId = isReplyThread ? postId.split('reply_')[1] : postId;

  // const originalPostRef = useRef(null);

  console.log(actualPostId)

  const fetchOriginalPost = useCallback(async () => {
    try {
      const endpoint = isReplyThread
      ? `https://www.iiicoast.com/api/get-reply/${actualPostId}`
      : `https://www.iiicoast.com/api/get-post/${postId}`;

      const response = await axios.get(endpoint);
      setOriginalPost(response.data);
    } catch (error) {
      console.error('Error fetching original post:', error);
    }
  }, [isReplyThread, actualPostId, postId]);

  const fetchReplies = useCallback(async () => {
    setIsLoading(true);
    setTimeout(async () => {
      try {
        console.log ("Page: ", page)
        const response = await axios.get(`https://www.iiicoast.com/api/get-replies/${page}/${actualPostId}`);
        const newReplies = response.data;
        if (page === 1) {
          setReplies(newReplies);
        } else {
          setReplies(prevReplies => [...prevReplies, ...newReplies]);
        }
  
        if (newReplies.length === 0) {
          setHasMore(false);
        } else {
          setPage(prevPage => prevPage + 1);
        }
      } catch (error) {
        console.error('Error fetching replies:', error);
      } finally {
        setIsLoading(false);
      }
    }, 500);
  }, [actualPostId, page]);

  const handleReplyPosted = useCallback(() => {
    // Reset the page and replies, then fetch the first page of replies again
    setPage(1);
    setReplies([]);
    fetchReplies();
  }, [fetchReplies]);

  // useEffect(() => {
  //   if (!originalPost) {
  //       fetchOriginalPost();
  //   }
  //   // fetchOriginalPost();
  //   // fetchReplies();
  // }, [fetchOriginalPost, originalPost]);

  // useEffect(() => {
  //   if (replies.length === 0) {
  //     console.log('Initial load: fetching first page of replies');
  //     fetchReplies();
  //   }
  // }, [replies.length, fetchReplies]);




  // UNDER CONTRUCTION

  const fetchParentChain = useCallback(async () => {
    if (!isReplyThread) return;

    try {
      const response = await axios.get(`https://www.iiicoast.com/api/get-parent-chain/${actualPostId}`);
      setParentChain(response.data);
    } catch (error) {
      console.error('Error fetching parent chain:', error);
    }
  }, [isReplyThread, actualPostId]);

  // useEffect(() => {
  //   if (isReplyThread) {
  //     fetchParentChain();
  //   }
  // }, [isReplyThread, fetchParentChain, actualPostId]);

  useEffect(() => {
    setOriginalPost(null);
    setParentChain([])
    setReplies([]);
    setPage(1);
    setHasMore(true);
    fetchOriginalPost();
  }, [postId, fetchOriginalPost]);

  // // Scroll to the original post when it is rendered
  // useEffect(() => {
  //   if (originalPostRef.current) {
  //     // Scroll to original post and align it to the top of the viewport
  //     originalPostRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  //   }
  // }, [originalPost]); // Only trigger when originalPost updates
  
  useEffect(() => {
    if (page === 1) {
      console.log(postId)
      console.log("Fetching replies...")
      fetchReplies();
      if (isReplyThread) {
        fetchParentChain();
      }
    }
    // if (isReplyThread) {
    //   fetchParentChain();
    // }
  }, [postId, isReplyThread, fetchParentChain, fetchReplies, page]); //

  // useEffect(() => {
  //   console.log(parentChain)
  // }, [parentChain])

  // const handleReplySubmit = async (content) => {
  //   try {
  //     // const userString = localStorage.getItem('user');
  //     // if (!userString) {
  //     //   console.error('User not logged in');
  //     //   return;
  //     // }

  //     // const user = JSON.parse(userString);
  //     const response = await axiosInstance.post('/v2/post_reply', {
  //       tweet: content,
  //       // user_id: user.uid,
  //       // username: user.username,
  //       // profile_picture_url: user.profile_picture_url,
  //       tweet_id: originalPost.id,
  //       poster_id: originalPost.pid
  //     });

  //     if (response.status === 201) {
  //       console.log('Reply submitted successfully');
  //       handleReplyPosted();
  //     }
  //   } catch (error) {
  //     console.error('Error submitting reply:', error);
  //   }
  // };

  const handleReplySubmit = async (content) => {
    try {
      const payload = {
        tweet: content,
        root_id: originalPost.tid || originalPost.id,  // tid for replies, id for tweets
        parent_id: originalPost.id,  // The immediate parent being replied to
        poster_id: originalPost.pid
      };
  
      const response = await axiosInstance.post('/v2/post_reply', payload);
  
      if (response.status === 201) {
        console.log('Reply submitted successfully');
        handleReplyPosted();
      }
    } catch (error) {
      console.error('Error submitting reply:', error);
    }
  };

  return (
    <ThreadContainer>
       {/* key={postId} */}
      {/* Render parent chain if it exists */}
      {parentChain.length > 0 && (
      // <ChainContainer>
      <>
        {parentChain.map((parent, index) => (
          <React.Fragment key={`parent_chain_${parent.id}`}>
            <Post
              // key={`parent_chain_${parent.id}`}
              // key={parent.id} // -${parent.date}-${index}
              post={parent}
              // isChainPost={true}
            />
            <ChainContainer />
            </React.Fragment>
        ))}
      </>
      //</ChainContainer>
      )}
      {/* ref={originalPostRef}  */}
      {originalPost && <Post post={originalPost} />} 
      <InfiniteScroll
        dataLength={replies.length}
        next={fetchReplies}
        hasMore={hasMore}
        loader={
          <SpinnerContainer>
            <BarLoader color="#888888" loading={isLoading} />
          </SpinnerContainer>
        }
        scrollThreshold={0.9}
      >
        <ReplyContainer>
            {replies.map((reply, index) => (
                <Post 
                  key={`${reply.id}-${reply.timestamp}-${index}`} 
                  post={reply} 
                  isReply={true}
                />
            ))}
        </ReplyContainer>
      </InfiniteScroll>
      {originalPost && (
        <FloatingActionButton
          onSubmit={handleReplySubmit}
        />
      )}
    </ThreadContainer>
  );
}

export default ViewThread;