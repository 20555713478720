import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ToastProvider } from './ToastContext';
import { PostsProvider } from './PostsContext';
import { AuthProvider } from './AuthContext';
import { AudioPlayerProvider } from "./AudioPlayerContext";
import { FABProvider } from './FABContext';
import { BrowserRouter as Router } from 'react-router-dom'; // , Navigate
import * as serviceWorkerRegistration from './serviceWorkerRegistration';  // Add this line

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <ToastProvider>
    <AuthProvider>
      <FABProvider>
        <PostsProvider>
          <AudioPlayerProvider>
            <Router>
              <App />
            </Router>
          </AudioPlayerProvider>
        </PostsProvider>
      </FABProvider>
    </AuthProvider>
  </ToastProvider>
  // </React.StrictMode>
);

// Register the service worker
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
